export function validatePasswordRules(passwordIn, passwordConfirmIn) {
    let password = (passwordIn || "").trim();
    let passwordConfirm = (passwordConfirmIn || "").trim();
    let errors = [];
    if (password.length < 14) {
        errors.push({code: "length"});
    }
    if (password.length > 50) {
        errors.push({code: "toolong"});
    }
    // at least an uppercase
    if (!/[A-Z]/.test(password)) {
        errors.push({code: "uppercase"});
    }
    // at least a lowercase
    if (!/[a-z]/.test(password)) {
        errors.push({code: "lowercase"});
    }
    // at least a number
    if (!/[0-9]/.test(password)) {
        errors.push({code: "digit"});
    }
    // password must match
    if (password !== passwordConfirm) {
        errors.push({code: "match"});
    }
    return errors;
}