export default () => ({
  init() {
    const { dateToCheck, timeGap } = this.$el.dataset
    const lastRefusedTransferRaw = localStorage.getItem(dateToCheck)
    const lastRefusedTransfer = lastRefusedTransferRaw ? Number(lastRefusedTransferRaw) : null

    const oneDay = 1000 * 60 * 60 * 24

    const doNotAskUntil = lastRefusedTransfer + (oneDay * Number(timeGap))
    if (doNotAskUntil > Date.now()) {
      this.$el.submit()
    }
  },
})