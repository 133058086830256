export default () => ({
    hasFocus: false,
    hasNoFocus : true,
    cl() { return { 'border-gray-800': this.hasFocus }},
    focus() { this.hasFocus = true, this.hasNoFocus = false},
    blur() { this.hasFocus = false },
    showPassword: false,
    toggleShowPassword() {
        this.showPassword = !this.showPassword;
        if (this.showPassword) {
            this.$refs.password.type = 'text';
            this.$refs.img.src = '/assets/images/icons/eye-open.svg';
        } else {
            this.$refs.password.type = 'password';
            this.$refs.img.src = '/assets/images/icons/eye-close.svg';
        }
    }
    })