export default (email) => ({
    invalid: true,
    confirmButtonClass: "disabledButton",
    checkMail() {
        const emailElements = document.getElementsByName('email');
        if (emailElements.length > 0) {
            const email = emailElements[0].value;
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            this.invalid = !emailRegex.test(email);
            this.confirmButtonClass = this.invalid ? "disabledButton" : "mainButton";
        }
    },
})