export default (user) => ({
    user: user,
    modalOpened: false,
    message: "",
    success: false,
    error: false,
    alertOpened: false,
    alertClass: "",
    resetPassword() {
        fetch(`/api/user/reset-password/${this.user.id}`)
            .then(response => response.json())
            .then(data => {
                this.success = data.success;
                this.error = !data.success;
                this.message = data.message;

               this.showAlert();
            })
            .catch(error => {
                this.success = false;
                this.error = true;
                this.message = "Error sending request";
                this.showAlert();
            })
        ;
    },
    showAlert(){
        this.toggleModal();
        if (this.success){
            this.alertClass ="bg-teal-500";
        }else{
            this.alertClass = "bg-orange-500";
        }
        this.alertOpened = true;
        setTimeout(() => {
            this.alertOpened = false;
        }, 3000);
    },

    toggleModal() {
        this.modalOpened = !this.modalOpened;
    },
    init() {
        const userLink = document.getElementById('reset-password');
        this.user = JSON.parse(userLink.getAttribute('data-user'));
    }
})