export default () => ({
  currentStep: 0,
  modalOpen: false,
  next() {
    this.currentStep = Math.min(3, this.currentStep + 1)
  },
  previous() {
    this.currentStep = Math.max(0, this.currentStep - 1)
  },
  toggleModal() {
    this.modalOpen = !this.modalOpen
  },
  isModalOpen() {
    return this.modalOpen
  },
  setStep0() {
    this.currentStep = 0
  },
  setStep1() {
    this.currentStep = 1
  },
  setStep2() {
    this.currentStep = 2
  },
  isCurrentStep0() {
    return this.currentStep === 0;
  },
  isCurrentStep1() {
    return this.currentStep === 1;
  },
  isCurrentStep2() {
    return this.currentStep === 2;
  },
  isCurrentStep3() {
    return this.currentStep === 3;
  },
  isDisabled0() {
    return this.currentStep !== 0;
  },
  isDisabled1() {
    return this.currentStep !== 1;
  },
  isDisabled2() {
    return this.currentStep !== 2;
  },
  isNotCurrentStep3() {
    return this.currentStep !== 3;
  },
  isNotCurrentStep0() {
    return this.currentStep !== 0;
  },
  storeChoice() {
    localStorage.setItem('transferOpvRefused', Date.now())
  },
})
