import {validatePasswordRules} from "../../app2/pages/anon/password/password-rules.js"
/* eslint-disable */
// alpinejs components
// use x-ref password and x-ref password-confirm to inputs
// on each change of password and password-confirm, validate the rules

export default () => ({
    hasLengthError: false,
    hasUppercaseError: false,
    hasLowercaseError: false,
    hasTooLongError: false,
    hasDigitError: false,
    hasMatchError: false,
    errors: {},
    hasErrors: true,
    passwordTextType: 'password',
    confirmPasswordTextType: 'password',
    passwordChange() {
        this.validate()
    },
    passwordConfirmChange() {
        this.validate()
    },
    validate() {
        this.password = this.$refs.passwordInput.value
        this.passwordConfirm = this.$refs.passwordConfirmInput.value
        this.errors = validatePasswordRules(this.password, this.passwordConfirm)
        this.hasLengthError = this.errors.some(e => e.code === "length")
        this.hasTooLongError = this.errors.some(e => e.code === "toolong")
        this.hasUppercaseError = this.errors.some(e => e.code === "uppercase")
        this.hasLowercaseError = this.errors.some(e => e.code === "lowercase")
        this.hasDigitError = this.errors.some(e => e.code === "digit")
        this.hasMatchError = this.errors.some(e => e.code === "match")
        this.hasErrors = this.errors.length > 0
    },
    init() {
        this.validate()
    },
    notHasLengthError() {
        return !this.hasLengthError
    },
    notHasTooLongError() {
        return !this.hasTooLongError
    },
    notHasUppercaseError() {
        return !this.hasUppercaseError
    },
    notHasLowercaseError() {
        return !this.hasLowercaseError
    },
    notHasDigitError() {
        return !this.hasDigitError
    },
    notHasMatchError() {
        return !this.hasMatchError
    },
    showMatchError() {
        return this.password && this.passwordConfirm && this.hasMatchError
    },
    lengthClass() {
        return { 'text-gray-400': !this.hasLengthError }
    },
    uppercaseClass() {
        return { 'text-gray-400': !this.hasUppercaseError }
    },
    lowercaseClass() {
        return { 'text-gray-400': !this.hasLowercaseError }
    },
    digitClass() {
        return { 'text-gray-400': !this.hasDigitError }
    },
    matchClass() {
        return { 'text-gray-400': !this.hasMatchError }
    },
    btnClass() {
        return this.hasErrors ? 'disabledButton': 'mainButton';
    },
    togglePassword() {
        this.passwordTextType = this.passwordTextType === 'password' ? 'text' : 'password';
        if (this.passwordTextType === 'text'){
            this.$refs.passwordImg.src = '/assets/images/icons/eye-open.svg';
        }else{
            this.$refs.passwordImg.src = '/assets/images/icons/eye-close.svg';
        }
    },
    toggleConfirmPassword() {
        this.confirmPasswordTextType = this.confirmPasswordTextType === 'password' ? 'text' : 'password';
        if (this.confirmPasswordTextType === 'text'){
            this.$refs.passwordConfirmImg.src = '/assets/images/icons/eye-open.svg';
        }else{
            this.$refs.passwordConfirmImg.src = '/assets/images/icons/eye-close.svg';
        }
    },
})