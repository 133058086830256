/* eslint-disable */
export default () => ({
    hasEmptyFields: true,
    hasEmptyCode : true,

    validateEmail() {
        this.email = this.$refs.email?.value
        this.validate()
    },
    validatePassword() {
        this.password = this.$refs.password?.value
        this.validate()
    },
    validateCode() {
        this.code = this.$refs.code?.value
        this.validate()
    },

    validate() {
        this.hasEmptyFields = !this.email || !this.password
        this.hasEmptyCode = !this.code
    },
     btnClass() {
        return this.hasEmptyFields ? 'disabledButton': 'mainButton';
    },
     btnClassCode() {
         return this.hasEmptyCode ? 'disabledButton' : 'mainButton';
        }

})
