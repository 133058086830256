import Alpine from '../external/alpine-csp/module.esm.js'
import focusFields from './light/focus-fields.js'
import passwordRules from './light/password-rules.js'
import loginMode from './login/login-mode.js'
import carouselMode from './login/carousel-mode.js'
import welcomeCarouselMode from './ui/Carousel/welcome-carousel-mode.js'
import transferOpvCheck from './ui/Carousel/transferOpvCheck.js'
import ResetPassword from '../app2/pages/authenticated/settings/resetPassword'
import lastPage from './ui/Button/lastPage'
import ValidMail from "../components/login/valid-mail.js";
import Location from "./light/location.js";
import loginRules from './login/login-rules.js'

window.Alpine = Alpine

Alpine.data('focus-fields', focusFields)
Alpine.data('login-mode', loginMode)
Alpine.data('login-rules', loginRules)
Alpine.data('password-rules', passwordRules)
Alpine.data('carousel-mode', carouselMode)
Alpine.data('last-page', lastPage)
Alpine.data('welcome-carousel-mode', welcomeCarouselMode)
Alpine.data("autoPostForm", () => ({
  init() {
    this.$el.submit()
  }
}))
Alpine.data('reset-password', ResetPassword)
Alpine.data('valid-mail', ValidMail)
Alpine.data('location', Location)

Alpine.data('checkSyncStatus', () => ({
  init() {
    const url = new URL(document.location.toString())
    const params = url.searchParams
    const tokenId = params.get('token_id')

    const maxTries = 75; // 5 minutes
    let currentIteration = 0;

    (function loop(tokenId) {
      setTimeout(async () => {
        currentIteration++

        if (currentIteration >= maxTries) {
          location.assign('/error')
        }

        const result = await fetch(url.pathname, {
          method: 'POST',
          body: JSON.stringify({ tokenId })
        }).then(payload => payload.json());
        (result && result.redirect)
          ? location.assign(result.redirect)
          : loop(tokenId)
      }, 4000)
    })(tokenId)
  }
}))
Alpine.data('transferOpvCheck', transferOpvCheck)
Alpine.start()
