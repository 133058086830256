export default (mode) => ({
    mode: mode || 'email',

    toggleMode() {
        this.mode = this.mode === 'email' ? 'code' : 'email'
    },
    isEmailMode() {
        return this.mode === 'email'
    },
    isCodeMode() {
        return this.mode === 'code'
    }
})